import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/layout';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
} from '@chakra-ui/react';

import NavItem from 'components/NavItem';

import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import * as styles from './Navbar.module.scss';

export const navbarQuery = graphql`
  {
    allStrapiNavigation {
      nodes {
        home
        rooms
        aboutUs
        gallery
        contact
      }
    }
  }
`;

export default function Navbar() {
  const data = useStaticQuery(navbarQuery);
  const navTitles = data.allStrapiNavigation.nodes[0];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const onOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Box as="nav" className={styles.navbarContainer}>
        <NavItem link="/" text={navTitles.home} />
        <NavItem link="/rooms" text={navTitles.rooms} />
        <NavItem link="/gallery" text={navTitles.gallery} />
        <NavItem link="/aboutUs" text={navTitles.aboutUs} />
        <NavItem link="/contact" text={navTitles.contact} />
      </Box>

      <>
        <Drawer placement="left" onClose={onClose} isOpen={isDrawerOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">Meniu</DrawerHeader>
            <DrawerBody>
              <Flex justify="center" align="center" direction="column" as="nav">
                <NavItem link="/" text={navTitles.home} />
                <NavItem link="/rooms" text={navTitles.rooms} />
                <NavItem link="/gallery" text={navTitles.gallery} />
                <NavItem link="/aboutUs" text={navTitles.aboutUs} />
                <NavItem link="/contact" text={navTitles.contact} />
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        <Box className={styles.hamburgerMenu}>
          <FontAwesomeIcon style={{ color: '#fff', fontSize: 25 }} onClick={onOpen} icon={faBars} />
        </Box>
      </>
    </>
  );
}
