import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import {
  Box, Flex, Text,
} from '@chakra-ui/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SweetHome from 'images/SweetHome.svg';
import TreeSwing from 'images/TreeSwing.svg';
import WaitingforYou from 'images/WaitingForYou.svg';
import StayingIn from 'images/StayingIn.svg';
import Photo from 'images/Photo.svg';
import Contact from 'images/Contact.svg';

import Navbar from 'components/Navbar';
import * as styles from './Header.module.scss';
import SEO from '../SEO/SEO';

const query = graphql`
{
  allStrapiLandingPage {
    nodes {
      background {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
  allStrapiPageTitles {
    nodes {
      strapiId
      title
      subtitle
    }
  }
}
`;

const getHeaderTitle = (currentPath, image, pageTitles) => {
  const getTitleClass = () => `
    ${styles.titleContainer} 
    ${styles.headerWrapper}
    `;

  const homePage = pageTitles.find((page) => page.strapiId === 5);
  const contactPage = pageTitles.find((page) => page.strapiId === 4);
  const aboutUsPage = pageTitles.find((page) => page.strapiId === 3);
  const galleryPage = pageTitles.find((page) => page.strapiId === 2);
  const roomsPage = pageTitles.find((page) => page.strapiId === 1);

  switch (currentPath) {
    case '/contact':
      return (
        <Box
          className={getTitleClass()}
        >
          <SEO title="Contact" description="Pagina de contact a proprietarilor pensiunii" />
          <Flex justify="center" align="center" direction="column">
            <Text as="h1" className={styles.titleText} fontWeight="500">{contactPage.title}</Text>
            <Text className={styles.subtitleText}>{contactPage.subtitle}</Text>
          </Flex>
          <Contact style={{ border: 'none' }} className={styles.headerImage} />
        </Box>
      );
    case '/gallery':
      return (
        <Box
          className={getTitleClass()}
        >
          <SEO title="Galerie" description="Pagina cu imagini din interiorul si exteriorul pensiunii" />
          <Flex justify="center" align="center" direction="column">
            <Text as="h1" className={styles.titleText} fontWeight="500">{galleryPage.title}</Text>
            <Text className={styles.subtitleText}>{galleryPage.subtitle}</Text>
          </Flex>
          <Photo style={{ maxHeight: 300, border: 'none' }} className={styles.headerImage} />
        </Box>
      );
    case '/rooms':
      return (
        <Box className={getTitleClass()}>
          <SEO title="Camere" description="Pagina cu informatii si imagini despre toate camerele pensiunii" />
          <Flex justify="center" align="center" direction="column">
            <Text as="h1" className={styles.titleText} fontWeight="500">{roomsPage.title}</Text>
            <Text className={styles.subtitleText}>{roomsPage.subtitle}</Text>
          </Flex>
          <StayingIn style={{ border: 'none' }} className={styles.headerImage} />
        </Box>
      );
    case '/aboutUs':
      return (
        <>
          <Box className={getTitleClass()}>
            <SEO title="Despre noi" description="Informatii despre istoria pensiunii, proprietari, obiceiuri" />
            <Flex justify="center" align="center" direction="column" style={{ marginTop: 50 }}>
              <Flex justify="center" align="center" direction="row">
                <WaitingforYou
                  style={{
                    maxHeight: 200, marginTop: 100, marginRight: -20, border: 'none',
                  }}
                  className={styles.aboutUsHeaderImage}
                />
                <SweetHome style={{ maxHeight: 350, border: 'none' }} className={styles.headerImage} />
                <TreeSwing
                  style={{
                    maxHeight: 200, marginTop: 100, marginLeft: -20, border: 'none',
                  }}
                  className={styles.aboutUsHeaderImage}
                />

              </Flex>
              <Text as="h1" className={styles.titleText} fontWeight="500">{aboutUsPage.title}</Text>
              <Text className={styles.subtitleText}>{aboutUsPage.subtitle}</Text>
            </Flex>
          </Box>
        </>
      );
    case '/':
      return (
        <>
          <Box
            className={getTitleClass()}
          >
            <Flex justify="center" align="center" direction="column">
              <Text as="h1" className={styles.titleText} align="center" fontWeight="500">{homePage.title}</Text>
              <Text className={styles.subtitleText} align="center">{homePage.subtitle}</Text>
            </Flex>
            <GatsbyImage
              image={image}
              alt="property-image"
              className={styles.headerImage}
            />
          </Box>

        </>
      );
    default:
      return (
        <></>
      );
  }
};

export default function Header({ currentPath }) {
  const data = useStaticQuery(query);
  const pageTitles = data.allStrapiPageTitles.nodes;
  const image = getImage(data.allStrapiLandingPage.nodes[0].background.localFile);

  return (
    <Box
      as="header"
      className={styles.headerContainer}
    >
      <Navbar />
      {getHeaderTitle(currentPath, image, pageTitles)}
    </Box>
  );
}

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
};
