import * as React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { Location } from '@reach/router';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import * as styles from './Layout.module.scss';
import '../../styles/global.css';

config.autoAddCss = false;

export default function Layout({ children }) {
  return (
    <>
      <Flex className={styles.contentContainer} direction="column" justify="flex-start" align="center">
        <Location>
          {({ location }) => (
            <Header currentPath={location.pathname} />
          )}
        </Location>
        <main className={styles.mainContainer}>{children}</main>
      </Flex>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
