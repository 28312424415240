import React from 'react';

import { Box, Flex, Link } from '@chakra-ui/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  return (
    <Flex
      style={{
        backgroundColor: '#323133', height: '10rem', color: '#FFF',
      }}
      justify="center"
      align="center"
      direction="column"
    >
      <Box>
        Pensiunea Codruta Marginea
      </Box>
      <Flex justify="center" align="center">
        <Link href="https://www.instagram.com/pensiuneacodrutamarginea/" isExternal>
          <FontAwesomeIcon size="2x" icon={faInstagram} />
        </Link>
        <Link href="https://www.facebook.com/profile.php?id=100074744623513" isExternal>
          <FontAwesomeIcon size="2x" icon={faFacebook} style={{ marginLeft: '0.8rem' }} />
        </Link>
      </Flex>
    </Flex>
  );
}
